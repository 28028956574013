@import url('https://fonts.googleapis.com/css2?family=Courier+Prime&family=Kalam:wght@300;400;700&family=Shadows+Into+Light&family=Quicksand:wght@400;600&display=swap');

:root {
  /* Fonts */
  --greeting-font: 'Kalam', cursive;
  /* --heading-font: 'The Girl Next Door', cursive; */
  /* --heading-font: 'Kalam', cursive; */
  --heading-font: 'Shadows Into Light', cursive;
  /* --body-font: 'Kalam', cursive; */
  --body-font: 'Quicksand', sans-serif;
  --code-font: 'Courier Prime', 'Courier New', monospace;

  /* Colors */
  --base-bg-color: #121212;
  --surface-bg-color: #222222;
  --main-text-color: #eeeeee;
  --text-accent-color: hsl(350, 90%, 65%);
  --accent-color: #03dac6;
  --shadow-color: #333333;
  --shadow-washed-color: hsl(174, 60%, 25%);

  /* Material */
  /* --main-shadow-blur: 0.2rem; */
  --main-shadow-blur: 0.2em;
  --ui-bottom-shadow: 0 0.3rem var(--main-shadow-blur) var(--shadow-color);
  --ui-left-shadow: -0.3rem 0 var(--main-shadow-blur) var(--shadow-color);
  --ui-right-shadow: 0.3rem 0 var(--main-shadow-blur) var(--shadow-color);
  --text-shadow: 0.02em 0.03em var(--text-accent-color);

  /* Spacing */
  --main-margin: 3rem;
  --section-gap: 2rem;
  --material-padding: 1rem;
  --compact-padding: 0.5rem;
  --inline-padding: 0.2rem;
  --nav-height: 55px;
  --width-centering-wrapper: 800px;

  /* Corners */
  --material-radius: 0.25rem;

  /* Other */
  scroll-behavior: smooth;
}

html {
  background-color: var(--base-bg-color);
}

body {
  font-family: var(--body-font);
  color: var(--main-text-color);

  /* For centering wrapper *appearance* */
  height: 100vh;
  width: 100%;
  max-width: var(--width-centering-wrapper);
  margin: 0 auto;
  background-color: var(--surface-bg-color);
  box-shadow: var(--ui-left-shadow), var(--ui-right-shadow);
}

h1,
h2,
h3,
h4 {
  color: var(--text-accent-color);
}

h2,
h3,
h4 {
  font-family: var(--heading-font);
}

h2 {
  font-size: 2em;
}

h2 span {
  display: inline-block;
  white-space: pre;
}

h3 {
  font-size: 1.5em;
}

.App {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: overlay;
  scroll-snap-type: y proximity;
  scroll-behavior: smooth;
}

.App::-webkit-scrollbar {
  width: 0.75em;
  background-color: transparent;
}
.App::-webkit-scrollbar-thumb {
  background: #444444;
}

section {
  min-height: calc(100% - var(--nav-height));
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  scroll-snap-align: start;
  scroll-margin-top: var(--nav-height);
}

.container {
  padding: var(--main-margin);
}

.container:not(.wide) {
  max-width: var(--width-centering-wrapper);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--main-margin);
}
.container > h2,
h2.rebel-h2 {
  text-align: center;
}
h2.rebel-h2 {
  margin: var(--section-gap);
  padding: 0 var(--main-margin);
}

#oscar {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--base-bg-color);
  box-shadow: none;
}

#oscar .container {
  /* Extra margin to protect the position: absolute h1 */
  margin: var(--section-gap) 0;
  position: relative;
  gap: var(--compact-padding);
}

#oscar h1 {
  width: 100%;
  padding-left: var(--main-margin);
  font-family: var(--greeting-font);
  font-size: 3em;

  white-space: nowrap;

  /* To center the paragraph, ignoring h1 height */
  position: absolute;
  top: calc(-1 * var(--section-gap));
  left: 0;
}

#oscar p {
  font-size: 1.5em;
  font-family: var(--greeting-font);
}

.navigation {
  height: var(--nav-height);
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  bottom: 0;
  z-index: 1;

  display: flex;

  background-color: var(--surface-bg-color);
  border: solid 0.1rem var(--accent-color);
  border-right: 0;
  border-left: 0;
  box-shadow: var(--ui-bottom-shadow);

  font-family: var(--heading-font);
  font-size: 1.5em;
  font-weight: bold;
  color: var(--surface-bg-color);
  color: var(--accent-color);
}

.navigation {
  animation: jump 1s 2s 1 cubic-bezier(0.65, 0.05, 0.36, 1);
}
@keyframes jump {
  25% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3rem);
  }
  80% {
    transform: translateY(0);
  }
}

.navigation ul {
  width: 100%;
  display: flex;
}

.navigation li {
  padding: 0 var(--compact-padding);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

/* Safari chokes on a click if transition is slower than this */
a.nav-link {
  transition: color 0.15s ease-out;
}

a.nav-link:hover {
  color: var(--main-text-color);
}

#projects {
  width: 100%;
  margin: 0;

  /* Override section defaults */
  max-width: none;
}

#projects .container {
  width: 100%;
  margin: 0;

  display: flex;
  flex-direction: row;
  gap: var(--main-margin);

  overflow-x: auto;
  overflow-y: hidden;
  background-color: var(--surface-bg-color);

  border: solid 1px var(--accent-color);
  border-right: 0;
  border-left: 0;
}

#projects .container::-webkit-scrollbar {
  display: none;
}

/* Fix missing side margins in Safari */
#projects .container::before,
#projects .container::after {
  flex: 0 0 1px;
  content: '';
}

.project-card {
  flex: 1 0 350px;

  padding: var(--material-padding);

  display: grid;
  grid-template-rows: auto auto auto;
  grid-template-columns: auto auto;
  grid-template-areas:
    'name links'
    'image image'
    'blurb blurb';

  border: solid 1px var(--accent-color);
  border-radius: var(--material-radius);
  background-color: var(--surface-bg-color);
  box-shadow: 0.35em 0.35em 0.25em var(--shadow-washed-color);
  transition: all 0.25s ease-out;
}

.project-card:hover {
  animation: amoeba 20s 1s infinite;
}
@keyframes amoeba {
  25% {
    border-radius: 5% 5% 20% 5%/15% 40% 5% 40%;
  }
  45% {
    border-radius: 30% 5% 20% 15%/5% 40% 5% 5%;
    transform: rotate(1.5deg);
  }
  65% {
    border-radius: 30% 15% 5% 15%/5% 5% 30% 5%;
    transform: rotate(-1deg);
  }
  80% {
    transform: scale(1.05);
  }
}

.project-card:hover {
  transform: scale(1.02);
  box-shadow: 0.6em 0.6em 0.75em var(--shadow-washed-color);
}

.project-card > h3,
.project-card > .links {
  align-self: baseline;
  white-space: nowrap;
}

.project-card > h3 {
  grid-area: name;
  margin: 0;
}

.project-card > .links {
  grid-area: links;
  justify-self: end;
  display: flex;
  gap: var(--compact-padding);
}

.project-card > img {
  grid-area: image;
  width: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}

.project-card > .blurb {
  grid-area: blurb;
  margin: 0;
  text-align: center;
}

#resume svg {
  margin: var(--compact-padding);
}

#resume a {
  white-space: nowrap;
}

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: var(--compact-padding);
  text-align: center;
  white-space: nowrap;
}

.about a {
  color: var(--text-accent-color);
}

.react-icons {
  align-self: center;
}

a.w-icon {
  flex: 0 0 auto;
  display: inline-flex;
  gap: var(--inline-padding);
}

#links a.w-icon {
  gap: var(--compact-padding);
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

a {
  transition: all 0.2s ease-out;
}

a:hover {
  color: var(--accent-color);
}

.about a:hover,
h2 a:hover,
h3 a:hover {
  text-shadow: var(--text-shadow);
  color: var(--text-accent-color);
}

button {
  cursor: pointer;
  border-radius: var(--material-radius);
  background-color: var(--surface-bg-color);
  box-shadow: #d6d6e7 0 3px 0;
  transition: background-color 0.4s ease-in, color 0.2s ease-out;
}

button:hover {
  color: var(--text-accent-color);
}

button.filtered,
button:active {
  box-shadow: #676767 0 3px 7px inset;
  transform: translateY(2px);
  background-color: var(--base-bg-color);
  color: var(--accent-color);
}

/********************
 *  Narrow Screens  *
 ********************/

@media only screen and (max-width: 900px) {
  :root {
    --main-margin: 3rem;
    --section-gap: 1.5rem;
    font-size: 15px;
  }
  body {
    max-width: none;
    width: calc(100% - (2 * var(--main-margin)));
    margin-left: var(--main-margin);
    margin-right: var(--main-margin);
  }
  section {
    margin: 0 var(--main-margin);
  }
  .container {
    padding: var(--section-gap);
  }
  .container:not(.wide) {
    max-width: none;
    gap: var(--material-padding);
  }
  h2.rebel-h2 {
    margin: var(--material-padding) var(--main-margin);
    padding: 0 var(--section-gap);
  }
  #oscar .container {
    margin: 0;
    padding: 0 var(--main-margin);
    gap: 0;
  }
  #oscar h1 {
    padding: 0;
    position: static;
  }
  #projects .container {
    padding: var(--main-margin) var(--section-gap);
  }
}

@media only screen and (max-width: 500px) {
  :root {
    --main-margin: 2rem;
    --section-gap: 1rem;
    --nav-height: 50px;
    font-size: 14px;
  }
  .navigation {
    font-size: 1.75em;
  }
  .navigation li:not(.current) span {
    display: none;
  }
  #projects .container {
    background-color: initial;
    border: none;
    scroll-snap-type: x mandatory;
  }
  .project-card {
    flex-basis: 85%;
    font-size: larger;
    scroll-snap-align: center;
  }
  .about {
    white-space: normal;
  }
}
