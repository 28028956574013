#skills {
  justify-content: flex-start;
}

#skills .container {
  margin-top: var(--main-margin);
}

.filters {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, min-content));
  justify-content: center;
  gap: var(--compact-padding);
  margin-bottom: var(--compact-padding);
}

@media only screen and (max-width: 800px) {
  .filters {
    grid-template-columns: repeat(3, minmax(100px, min-content));
  }
}

.filters button {
  font-family: var(--body-font);
  white-space: nowrap;
}

.skill-list {
  display: flex;
  flex-flow: row wrap;
  gap: var(--compact-padding);
  align-items: center;
  justify-content: center;
}
.skill-list.filtered {
  row-gap: var(--inline-padding);
}

.skill.acc {
  display: none;
}

.skill {
  margin: 0;
  padding: 0;
  border: solid 1px var(--accent-color);
  border-radius: var(--material-radius);
  line-height: 1.1;
  font-size: smaller;
  transition: font-size 0.25s, border-color 1s;
}
.skill.unfiltered {
  padding: var(--inline-padding);
}

.skill.filtered {
  font-size: larger;
  border: none;
}

.filtered .skill.unfiltered {
  color: #555555;
  border: solid 1px #555555;
}

/* Front-End Cards */
.skill.filtered.fe {
  border-top: 14px solid white;
  border-right: 6px solid white;
  border-bottom: 6px solid white;
  border-left: 16px solid white;
  border-image-source: url(../../public/images/fi-layout-fe.svg);
  border-image-slice: 7 3 3 8;
  border-image-repeat: stretch;

  /* text gradient */
  background: var(--main-text-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Back-End Cards */
.skill.filtered.be {
  padding-top: var(--inline-padding);
  border-top: 14px solid white;
  border-right: 6px solid white;
  border-bottom: 6px solid white;
  border-left: 6px solid white;
  border-image-source: url(../../public/images/fi-layout-be.svg);
  border-image-slice: 7 3 3 3;
  border-image-repeat: stretch;
  background-color: black;
  background-clip: padding-box;
  color: white;
  font-family: var(--code-font);
}

/* Languages Cards */
.skill-list.lang {
  justify-content: flex-start;
  padding: var(--material-padding);
  padding-left: var(--main-margin);
  row-gap: var(--compact-padding);
}
.skill.filtered.lang {
  font-family: var(--code-font);
  order: 1;
}
.skill.filtered.lang.par {
  order: 4;
}
.skill.filtered.lang::after {
  content: ',';
}
.skill.acc.lang-acc.filtered.lang {
  width: 100%;
  display: initial;
  margin-left: calc((-1 * var(--main-margin)) + var(--material-padding));
  color: var(--accent-color);
}
.skill.acc.lang-acc.filtered.lang span {
  color: #bb86fc;
}
.skill.acc.lang-acc.filtered.lang::after {
  content: none;
}
.skill.acc.lang-acc-0 {
  order: 0;
}
.skill.acc.lang-acc-1 {
  order: 2;
}
.skill.acc.lang-acc-2 {
  order: 3;
}
.skill.acc.lang-acc-3 {
  order: 5;
}
.skill-list.lang .unfiltered {
  display: none;
}

/* Dev Tools Cards */
.skill.filtered.dev {
  padding-top: var(--inline-padding);
  border-top: 14px solid white;
  border-right: 6px solid white;
  border-bottom: 6px solid white;
  border-left: 6px solid white;
  border-image-source: url(../../public/images/fi-layout-be.svg);
  border-image-slice: 7 3 3 3;
  border-image-repeat: stretch;
  background-color: black;
  background-clip: padding-box;
  color: #00ff00;
  font-family: var(--code-font);
}
.skill.filtered.dev::before {
  content: '>';
  margin-right: var(--inline-padding);
}
.skill.filtered.dev::after {
  content: '▁';
  margin-right: var(--inline-padding);
  animation: 1.35s blink step-end infinite;
}
@keyframes blink {
  from,
  to {
    color: inherit;
  }
  50% {
    color: black;
  }
}

/* Framweworks Cards */
.skill.fw {
  padding: var(--inline-padding) var(--compact-padding);
  border: 2px dashed var(--text-accent-color);
  display: flex;
  align-items: center;
  gap: var(--inline-padding);
}
.skill.fw::before {
  content: url(../../public/images/fi-settings-fw.svg);
  height: 24px;
  width: 24px;
  animation: spin 7s linear infinite;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Embedded Cards */

.skill.embed {
  padding: var(--inline-padding) var(--compact-padding);
  border: 4px double transparent;
  display: flex;
  align-items: center;
  gap: var(--inline-padding);
  animation: glow 4s 0.35s infinite ease-out;
}
@keyframes glow {
  to,
  from {
    border: 4px double transparent;
  }
  50% {
    border: 4px double var(--text-accent-color);
  }
}

@media only screen and (max-width: 500px) {
  .skill.filtered {
    font-size: 1.05em;
  }
}
