@import '~sanitize.css';
@import '~sanitize.css/forms.css';
@import '~sanitize.css/typography.css';

/* html,
body {
  height: 100vh;
} */

/* Do not render box for root div */
/* Not needed since .App is fixed 100% height */
/* .root {
  display: contents;
} */

/* Override sanitize.css content to prevent layout shift */
:where(nav li)::before {
  content: none;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
